import React from "react"
import { Box, Grid, Text, Container } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import { ArrowRight } from "react-feather"
import { InboundLink } from "../link"
import linkSwitch from "../../utils/linkSwitch"

const ImageAndText = ({
  title,
  body,
  images,
  subtitle,
  link,
  paddingtop,
  centerimage,
}) => {
  console.log(centerimage)
  return (
    <Box sx={{ py: [3, 3, 3, 3], position: "relative" }}>
      <Container>
        <Grid
          columns={[1, 1, "1fr 1fr"]}
          sx={{ alignItems: centerimage ? "center" : "start" }}
          gap={[0, 0, 6, 6]}
        >
          <Box>
            {images.length > 1 ? (
              <Grid
                sx={{
                  gridTemplateColumns: "repeat(7,1fr)",
                  gridTemplateRows: "repeat(4,1fr)",
                }}
              >
                {images.map((image, index) => {
                  return (
                    <Box
                      sx={{
                        gridColumn: index === 0 ? "1/5" : "3/8",
                        gridRow: index === 0 ? "1/3" : "2/8",
                        ".gatsby-image-wrapper": {},
                      }}
                    >
                      <GatsbyImage image={image.gatsbyImageData} alt="" />
                    </Box>
                  )
                })}
              </Grid>
            ) : (
              <>
                <Box
                  sx={{
                    p: [4],
                    ".gatsby-image-wrapper": {},
                  }}
                >
                  <GatsbyImage image={images.gatsbyImageData} alt="" />
                </Box>
              </>
            )}
          </Box>
          <Box sx={{ py: paddingtop ? [2, 2, 0, 0] : [2, 2, 3, 7] }}>
            <Box>
              <Text variant="caption"> {subtitle}</Text>
            </Box>
            <Box>
              <Text
                as="div"
                variant="sectionTitle"
                sx={{
                  py: 3,
                  pb: 4,
                }}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </Box>
            <Box sx={{}}>
              <Text
                sx={{
                  p: [3, 6, 6, 6],
                  display: "block",
                  ul: {
                    mt: "10px",
                    li: {
                      position: "relative",
                      span: {
                        ml: [3, 3, 3, 3],
                      },
                      "span:before": {
                        content: "'//'",
                        color: "primary",
                        position: "absolute",
                        left: [-2, -2, -2, -2],
                      },
                    },
                  },
                  a: {
                    paddingY: 3,
                    paddingX: 5,
                    cursor: "pointer",
                    color: "light",
                    bg: "primary",
                    "&:hover": {
                      bg: "dark",
                    },
                  },
                  py: [3, 3, 3, 3],
                }}
                variant="sectionTitle"
                dangerouslySetInnerHTML={{ __html: body }}
              />
              {link && (
                <Box sx={{ p: [3, 6, 6, 6] }}>
                  <InboundLink
                    href={linkSwitch(link, link.locale).href}
                    sx={{ fontWeight: "bold" }}
                    variant="buttons.full.primary"
                  >
                    {link.anchor} +
                  </InboundLink>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default ImageAndText
