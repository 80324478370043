import React from "react"
import {
  Box,
  Text,
  Grid,
  Heading,
  Container,
  Flex,
  Image,
} from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"

const GridParagraphAndIcon = ({
  label,
  title,
  subtitle,
  items,
  isHome = true,
}) => {
  return (
    <Box sx={{ py: [3, 3, 3, 3] }}>
      <Container sx={{ overflow: "hidden", py: [3, 3, 0, 0] }}>
        {label && (
          <Flex sx={{ justifyContent: "center" }}>
            <Text variant="caption">{label}</Text>
          </Flex>
        )}
        {title && (
          <Box
            sx={{
              width: "100%",

              zIndex: -1,
              position: "relative",
              h2: {
                left: "50%",
                transform: "translateX(-50%)",
                position: "absolute",
                fontSize: [9, 9, 10, 14],
                zIndex: -1,
                color: "gray",
              },
            }}
          >
            <Heading variant="h2">{title}</Heading>
          </Box>
        )}
        {subtitle && (
          <Flex
            sx={{
              justifyContent: "center",
              px: [2, 3, 6, 9],
              pt: [2, 3, 4, 6],
              pb: [3, 6, 8, 9],
              textAlign: "center",
            }}
          >
            <Text
              as="div"
              variant="sectionTitle"
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
          </Flex>
        )}
        <Grid columns={[1, 1, "1fr 1fr 1fr"]} gap={[isHome ? 0 : 3, 3, 4, 4]}>
          {items.map((item, map) => {
            return isHome ? (
              <Flex
                sx={{
                  flexDirection: "column",
                  p: [3, 3, 3, 4],
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "100%", pb: [3], textAlign: "center" }}>
                  <Heading variant="h5" as="h5">
                    {item.title}
                  </Heading>
                </Box>
                <Box sx={{ width: "100%", pt: [1], textAlign: "center" }}>
                  <RichContentStructuredText text={item.body} />
                </Box>
                <Box
                  sx={{
                    p: [3, 3, 4, 4],
                    ".gatsby-image-wrapper": {
                      height: "100px",
                    },
                  }}
                >
                  {item.image.gatsbyImageData ? (
                    <GatsbyImage image={item.image.gatsbyImageData} alt="" />
                  ) : (
                    <Image
                      className="gatsby-image-wrapper"
                      src={item.image.url}
                      alt=""
                    />
                  )}
                </Box>
              </Flex>
            ) : (
              <Flex
                sx={{
                  flexDirection: "column",
                  border: "1px solid",
                  borderColor: "dark",
                  p: [4],
                  pb: [6],
                  pt: [4],
                }}
              >
                <Box
                  sx={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Flex
                    sx={{
                      alignItems: "center",
                      py: [4],
                      ".gatsby-image-wrapper": {
                        width: "60px",
                      },
                    }}
                  >
                    {item.image.gatsbyImageData ? (
                      <GatsbyImage image={item.image.gatsbyImageData} alt="" />
                    ) : (
                      <Image
                        className="gatsby-image-wrapper"
                        src={item.image.url}
                        alt=""
                      />
                    )}
                    <Heading variant="h5" m={0} as="h5" pl={[3]}>
                      {item.title}
                    </Heading>
                  </Flex>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    pt: [1],
                    textAlign: "left",
                    p: { letterSpacing: "2px" },
                  }}
                >
                  <RichContentStructuredText text={item.body} />
                </Box>
              </Flex>
            )
          })}
        </Grid>
      </Container>
    </Box>
  )
}

export default GridParagraphAndIcon
