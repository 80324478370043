import React from "react"
import { Box, Grid, Text, Container, Link } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import { InboundLink } from "../link"
import linkSwitch from "../../utils/linkSwitch"

const BannerHome = ({ block }) => {
  const { body, bannerImage, pageLink } = block

  return (
    <Container sx={{ bg: block.background.hex, position: "relative" }}>
      <Grid
        columns={[1, 1, "1fr 1fr"]}
        gap={[4, 5, 6]}
        sx={{
          alignItems: "center",
        }}
      >
        <Box sx={{ p: [3, 4] }}>
          <Text
            as="div"
            sx={{
              em: {
                color: "primary",
              },
              img: {
                display: "block",
                height: "53px",
              },
              fontSize: [3, 4, 5],
              lineHeight: 1.2,
              color: "light",
              mb: 4,
              h2: {
                fontWeight: "700",
                fontSize: "48px",
                em: {
                  fontStyle: "normal",
                },
              },
              p: {
                mb: 4,
                fontSize: "20px",
                color: "light",
              },
              a: {},
            }}
            dangerouslySetInnerHTML={{ __html: body }}
          />
          {pageLink && (
            <>
              {pageLink.model.apiKey === "internal_link" && (
                <InboundLink
                  href={linkSwitch(pageLink, pageLink.locale).href}
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    px: 4,
                    py: 3,
                    width: "80%",
                    justifyContent: "space-around",
                    bg: "transparent",
                    color: "light",
                    border: "1px solid",
                    borderColor: "light",
                    textDecoration: "none",
                    fontWeight: "bold",
                    borderRadius: "4px",
                    transition: "all 0.2s ease-in-out",
                    "&:hover": {
                      bg: "light",
                      color: "dark",
                    },
                  }}
                >
                  {pageLink.anchor}
                  <Box as="span" sx={{ ml: 2 }}>
                    →
                  </Box>
                </InboundLink>
              )}
              {pageLink.model.apiKey === "external_link" && (
                <Link
                  href={pageLink.url}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    px: 4,
                    py: 3,
                    width: "80%",
                    gap: "20px",
                    bg: "transparent",
                    color: "light",
                    border: "1px solid",
                    borderColor: "light",
                    textDecoration: "none",
                    fontWeight: "bold",
                    borderRadius: "4px",
                    transition: "all 0.2s ease-in-out",
                    "&:hover": {
                      bg: "light",
                      color: "dark",
                    },
                  }}
                >
                  {pageLink.anchor}{" "}
                  <Box sx={{ mt: "1px", fontSize: "24px" }} as="span">
                    →
                  </Box>
                </Link>
              )}
            </>
          )}
        </Box>

        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            width: "50%",
            height: "100%",
            "@media screen and (max-width: 768px)": {
              position: "relative",
              width: "100%",
              minHeight: "300px",
            },
          }}
        >
          <GatsbyImage
            image={bannerImage.gatsbyImageData}
            alt=""
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
      </Grid>
    </Container>
  )
}

export default BannerHome
